<template>
<div
    class="bg-purple-150  bg-gradient-to-br from-pink-250 to-yellow-150 w-screen "
  >
  <nav>
    <div class="icon ">
    <router-link :to="{ name: 'Name', params: { names: this.names } }" class="ml-16 mt-16  cursor-pointer text-white font-bold text-4xl arrow "></router-link>
    </div>
  </nav>

<div class="mx-4 ">
  <div>
    <div class="mt-8 md:mt-24 mx-12 text-white font-bold text-2xl underline">

    Mode :
    </div>
  <div class=" mt-8 pb-4 mx-12 justify-between  flex overflow-x-auto overflow-hidden md:justify-between lg:mt-12">
    <div
      v-for="(mode, index) in modes"
      v-on:click="selectMode(mode.mode)"
      :class="{ [colorsMode[index]]: this.mode.includes(mode.mode) , [colorsModeShadows[index]]: this.mode.includes(mode.mode)} "
      class="mode transition-all relative shadow-gris-250 bg-white cursor-pointer  rounded-xl mr-6 flex flex-col md:mr-2" 
      :key="mode.id"
    >
      <p class="text-center font-bold text-black mt-6  md:text-3xl" :class="{ 'text-white': this.mode.includes(mode.mode) } ">
        {{ mode.mode }}
      </p>
      <p class="text-center mt-6 text-2xl">{{ mode.emojis }}</p>
    </div>
  </div>
  </div>
  <div>
    <div>
<div class="mt-8 md:mt-24 mx-12 text-white font-bold text-2xl underline">

Nombre de tours :
</div>
    </div>
  <div class="mt-8 mx-12 text-4xl flex justify-between font-bold lg:mt-12 ">
    <div
      :class="{ 'bg-purple-150': turn == this.turn ,'text-white': turn == this.turn,'shadow-violet-250': turn == this.turn, 'hover:shadow-gris-250': turn != this.turn}"
      class=" transition-all bg-white cursor-pointer text-black px-4 py-2 md:px-8 md:py-2 rounded-full text-xl filter"
      v-for="turn in turns"
      :key="turn.id"
      v-on:click="selectTurn(turn)"
    >
      <div>
        {{ turn }}
      </div>
    </div>
  </div>
  </div>
  </div>
  <nav class="text-center text-white font-bold mt-8 md:mt-28 lg:mt-12 sticky">
    <router-link
      :to="{
        name: 'Quizz',
        params: {
          names: this.names,
          mode: JSON.stringify(this.mode),
          turn: this.turn,
        },
      }"
      class="bg-green-150 text-xl px-4 py-2 md:px-8 md:py-2 rounded-full md:text-4xl shadow-vert-250 "
      >Lancer</router-link
    >
  </nav>
  </div>
</template>

<script>
export default {
  name: "modeSelection",
  props: ["names"],
  data() {
    return {
      mode: ["Aleatoire"],
      turn: 200,
      turns: [50, 100, 200],
      colorsMode:['bg-yellow-150','bg-purple-150','bg-blue-150','bg-pink-150','bg-green-150'],
      colorsModeShadows:['shadow-rouge-150','shadow-violet-250','shadow-bleu-250','shadow-rose-250','shadow-vert-250'],
      modes: [
        { mode: "Aleatoire", emojis: "🎲"},
        { mode: "Light", emojis: "🤏" },
        { mode: "Hard", emojis: "🔥" },
        { mode: "Caliente", emojis: "🥵" },
        // { mode: "Test", emojis: "⚙️"},
      ],
    };
  },
  methods: {
    selectMode(mode) {
      //if click on other thing that random, uncheck random OR if click random, uncheck all modes
      if (this.mode.includes("Aleatoire")||mode =="Aleatoire" ) {
        this.mode = [];
      }
      //unselect the mode
      if (this.mode.includes(mode)) {
        this.mode.splice(this.mode.indexOf(mode), 1);
      } else {
        //select the mode
        this.mode.push(mode);
      }

      //if nothing selected, go random
      if (this.mode.length==0) {
        this.mode = ["Aleatoire"];
      }
    },
    selectTurn(nTurn) {
      this.turn = nTurn;
      console.log(this.turn);
    },
    test() {
      console.log(this.modes);
    },
  },
};
</script>

<style>
</style>

<template>
 <router-view class="h-screen  max-h-full overflow-hidden"/>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

</style>

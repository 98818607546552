import { createRouter, createWebHashHistory } from "vue-router";
import Name from "@/views/addNames.vue"
import Mode from "@/views/modeSelection.vue"
import Quizz from "@/views/quizz.vue"


const routes = [
    {
        name:"Name",
        path:"/:names?",
        component: Name,
        props:true
    },
    {
        name:"Mode",
        path:"/mode/:names",
        component: Mode,
        props:true
    },
    {
        name:"Quizz",
        path:"/quizz/:names/:mode/:turn",
        component: Quizz,
        props:true
    }
]

const router = createRouter({
    history:createWebHashHistory(),
    routes: routes
})

export default router;
<template>
  <div
    class="bg-purple-150 bg-gradient-to-br from-purple-150 to-blue-150 w-screen"
  >
    <div
      v-if="this.warning"
      class="z-10 fixed left-0 top-0 bg-black bg-opacity-50 h-full w-full"
    >
      <div class="m-auto mt-40 bg-white w-4/5 pb-6 flex flex-col rounded">
        <h1 class="text-xl font-bold mx-12 mt-6">Attention</h1>
        <p class="mx-12 mt-6 text-gray-600">
          L'abus d'alcool est dangereux pour la santé. En poursuivant vous
          confirmez être responsable des éventuelles conséquences que pourrait
          engendrer l'utilisation de Picolo
        </p>
        <p
          v-on:click="warningSwitch"
          class="shadow-violet-250 cursor-pointer bg-violet-150 w-1/4 py-2 mb-6 mt-12 mx-auto text-center text-white font-bold rounded-full"
        >
          J'ai compris
        </p>
      </div>
    </div>
    <div
      class="text-center pt-16 text-white font-bold text-5xl  md:text-7xl lg:text-9xl"
    >
      DrunkBox
    </div>
    <div class="mx-6">
      <div class="my-14 nameForm flex justify-center px-1/2 justify-around">
        <input
          class="border-b-2 border-white bg-transparent focus:outline-none placeholder-white text-white font-bold"
          type="text"
          v-model="this.nameInput"
          placeholder="Name..."
          v-on:keyup.enter="addNameToList(nameInput, true)"
        />
        <div
          v-on:click="addNameToList(nameInput, true)"
          class="transition-all text-white font-bold text-3xl text-center rounded cursor-pointer h-12 w-12 flex"
        >
          +
        </div>
      </div>
      <div
        class="flex flex-col mt-12 overflow-y-scroll"
        :class="{ 'h-80': this.namesArr.length > 1 }"
      >
        <div
          class="listNames flex my-4 justify-between lg:justify-around md:justify-around"
          v-for="(name, index) in this.namesArr"
          v-bind:key="name.id"
        >
          <div class="flex flex-col">
            <div class="flex flex-col">
              <label class="switch">
                <input
                  type="checkbox"
                  class="switch m-auto"
                  v-model="name.gender"
                />
                <span class="slider round"> </span>
              </label>
              <div
                v-if="name.gender"
                class="transition-all text-xs text-white m-auto w-12 text-center"
              >
                Homme
              </div>
              <div
                v-else
                class="transition-all text-xs text-white m-auto w-12 text-center"
              >
                Femme
              </div>
            </div>
          </div>
          <div class="text-white font-bold text-xl">
            {{ name.name }}
          </div>

          <div
            v-on:click="deletePlayer(index)"
            class="text-white font-bold text-xl cursor-pointer"
          >
            X
          </div>
        </div>
      </div>
    </div>
    <nav
      class="text-center text-xl text-white font-bold fixed bottom-20 translateX left-1/2 lg:bottom-8"
    >
      <router-link
        v-if="this.namesArr.length > 1"
        class="filter shadow-jauneVert-250 text-3xl bg-yellowGreen-150 px-8 py-2 rounded-full"
        :to="{ name: 'Mode', params: { names: JSON.stringify(this.namesArr) } }"
        >Jouer</router-link
      >
    </nav>
  </div>
</template>

<script>
export default {
  name: "addNames",
  props: ["names"],
  data() {
    return {
      nameInput: "",
      namesArr: [],
      warning: true,
    };
  },
  methods: {
    addNameToList(name, gender) {
      if (this.nameInput != "" || this.nameInput == undefined) {
        this.namesArr.push({ name: name, gender: gender });
      }
      this.nameInput = "";

    },
    deletePlayer(i) {
      this.namesArr.splice(i, 1);
    },
    warningSwitch() {
      this.warning = !this.warning;
    },
  },
  beforeMount() {
    
if (this.names != "") {
  this.warning = false
      JSON.parse(this.names).forEach((e) => {
        this.namesArr.push(e);
      });
    }
  },
};
</script>

<style></style>

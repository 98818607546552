<template>
  <div
    class="bg-gradient-to-br w-screen"
    :class="
      'bg-' +
      this.colors.color1 +
      ' from-' +
      this.colors.color1 +
      ' to-' +
      this.colors.color2
    "
  >
    <nav>
      <div class="icon">
        <router-link
          :to="{ name: 'Mode', params: { names: this.names } }"
          class="ml-16 mt-16 cursor-pointer text-white font-bold text-4xl arrow"
        ></router-link>
      </div>
    </nav>
    <div
      v-on:click="newQuestion"
      :class="[
        'bg-' + this.colors.primary + ' filter shadow-' + this.colors.secondary,
        { 'scale1-0 ': turnedButtonAnim },
        { 'scale0-1 ': !turnedButtonAnim },
      ]"
      class="cursor-pointer transition-all mx-auto text-xl box-border rounded-full button-quizz text-white font-bold flex justify-center items-center text-9xl italic mt-32 cursor-pointer lg:mt-12"
      v-if="!turnedButton"
    >
      <p :class="{ spin: turnedButtonAnim }">?</p>
    </div>
    <div v-if="turnedMessage">
      <div
        v-html="this.questionsPlayable[0].message"
        ref="containerQuestion"
        class="transition-all text-white text-center text-xl md:text-4xl mx-6 mt-32 font-bold lg:mx-12"
        :class="{ 'scale0-1 ': turnedButtonAnim }"
      ></div>
      <h1
        v-on:click="newTurn"
        :class="
          'bg-' +
          this.colors.primary +
          ' filter shadow-' +
          this.colors.secondary
        "
        class="cursor-pointer text-white filter text-xl md:text-3xl font-bold px-8 py-2 rounded-full fixed bottom-20 translateX left-1/2 text-center"
      >
        Next
      </h1>
    </div>
    <div
      v-html="this.replaceEmojiContainer(this.questionsPlayable[0].mode)"
      :class="{ 'scale0-1 ': turnedButtonAnim }"
      v-if="turnedMessage"
      ref="containerEmojis"
      class="text-2xl md:text-4xl fixed bottom-12 right-12"
    ></div>
    <nav v-if="endGame">
      <router-link
        :to="{ name: 'Mode', params: { names: this.names } }"
        class="transition-all cursor-pointer bg-red-150 filter shadow-red-250 text-white text-center filter text-xl font-bold px-4 py-2 rounded-full fixed top-1/2 translateXY left-1/2 lg:text-3xl"
        >Go back to mode</router-link
      >
    </nav>
  </div>
</template>

<script>
import questions from "../assets/questions.json";

export default {
  name: "Quizz",
  props: ["names", "mode", "turn"],
  data() {
    return {
      questions: questions,
      modes: [],
      guys: [],
      girls: [],
      allPlayers: [],
      questionsPlayable: [],
      emojisPlayable: [],
      counterQuestionsAdded:0,
      turnedButton: false,
      turnedButtonAnim: false,
      turnedMessage: false,
      endGame: false,
      testS: "tezrefsdfsd",
      colors: {},
      colorsCombinaison: [
        {
          color1: "rouge-150",
          color2: "jaune-150",
          primary: "bleu-150",
          secondary: "bleu-250",
        },
        {
          color1: "violet-250",
          color2: "bleu-150",
          primary: "vert-150",
          secondary: "vert-250",
        },
        {
          color1: "vert-250",
          color2: "jauneVert-150",
          primary: "violet-150",
          secondary: "violet-250",
        },
        {
          color1: "violet-250",
          color2: "rose-150",
          primary: "jaune-150",
          secondary: "jaune-250",
        },
        {
          color1: "rose-250",
          color2: "violet-150",
          primary: "vert-150",
          secondary: "vert-250",
        },
      ],
    };
  },
  methods: {
    test() {},
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;
      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }
      return array;
    },
    getFirstLowerCase(string) {
      return string.toLowerCase().charAt(0);
    },
    getRandomPlayer() {
      let x = this.getRandomInt(0, 1);
      var arrayOfNames = [];
      if (x == 1) {
        arrayOfNames = this.guys;
      } else {
        arrayOfNames = this.girls;
      }
      return arrayOfNames[this.getRandomInt(0, arrayOfNames.length)];
    },
    replaceEmoji(str, emoji, repl) {
      return str.replaceAll(emoji, repl);
    },
    replaceEmojiContainer(str) {
      //  let m = this.questionsPlayable[0].mode;
      str = this.replaceEmoji(str, "l", "🤏");
      str = this.replaceEmoji(str, "h", "🔥");
      str = this.replaceEmoji(str, "c", "🥵");
      str = this.replaceEmoji(str, "t", "⚙️");
      return str;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async newQuestion() {
      this.turnedButtonAnim = !this.turnedButtonAnim;
      await this.sleep(1000);

      this.changeColors();

      if (this.questionsPlayable.length > 0) {
        this.turnedMessage = !this.turnedMessage;
        this.turnedButton = !this.turnedButton;
        // this.$nextTick(() => {
        //   this.shuffle(this.guys);
        //   this.shuffle(this.girls);
        //   this.shuffle(this.allPlayers);
        //   let str = this.questionsPlayable[0].message;
        //   let quest = str;

        //   // let ananas =

        //   if (quest.includes("🍒")) {
        //     quest = this.replaceEmoji(quest, "🍒", this.girls[1].name);
        //   }
        //   if (quest.includes("🍑")) {
        //     quest = this.replaceEmoji(quest, "🍑", this.girls[0].name);
        //   }
        //   if (quest.includes("🥒")) {
        //     quest = this.replaceEmoji(quest, "🥒", this.guys[1].name);
        //   }
        //   if (quest.includes("🍆")) {
        //     quest = this.replaceEmoji(quest, "🍆", this.guys[0].name);
        //   }
        //   if (quest.includes("🍉")) {
        //     quest = this.replaceEmoji(quest, "🍉", this.allPlayers[1].name);
        //   }
        //   if (quest.includes("🍍")) {
        //     quest = this.replaceEmoji(quest, "🍍", this.allPlayers[0].name);
        //   }

        //   this.$refs.containerQuestion.innerHTML = quest;

        //   let m = this.questionsPlayable[0].mode;
        //   let me = m;
        //   me = this.replaceEmoji(me,'l','🤏');
        //   me = this.replaceEmoji(me,'h','🔥');
        //   me = this.replaceEmoji(me,'c','🥵');
        //   this.$refs.containerEmojis.innerHTML =me;
        // });
      }
    },
    newTurn() {
      this.turnedButtonAnim = !this.turnedButtonAnim;
      this.changeColors();

      // this.turned = !this.turned;
      this.$nextTick(() => {
        if (
          this.questionsPlayable.length >= 1 &&
          this.questionsPlayable.length != undefined
        ) {
          this.questionsPlayable.shift();
          if (this.questionsPlayable.length > 0) {
            this.turnedMessage = !this.turnedMessage;
            this.turnedButton = !this.turnedButton;
          } else {
            this.turnedMessage = !this.turnedMessage;

            this.endGame = !this.endGame;
          }
        }
      });
    },
    changeColors() {
      this.shuffle(this.colorsCombinaison);
      this.colors = this.colorsCombinaison[0];
    },
  },
  mounted() {
    //?change colors of bacckground
    this.changeColors();

    this.colors = this.colorsCombinaison[0];

    //?add names to differents array likes [girls], [guys] and [allPlayers]
    JSON.parse(this.names).forEach((e) => {
      switch (e.gender) {
        case true:
          this.guys.push(e);
          break;

        default:
          this.girls.push(e);
          break;
      }
    });
    this.girls.forEach((e) => {
      this.allPlayers.push(e);
    });
    this.guys.forEach((e) => {
      this.allPlayers.push(e);
    });

    //?get the mode(s) selected in the array [modes]
    JSON.parse(this.mode).forEach((e) => {
      if (this.getFirstLowerCase(e) == "a") {
        this.modes = ["l", "c", "h"];
      } else {
        this.modes.push(this.getFirstLowerCase(e));
      }
    });

    //?get all the questions of the selected mode(s) in the arr [questionsPlayables]
    //?get the questions with the good numbers of persons ( ex: if need 2 girls and only have one, do not pick)
    this.questions.forEach((e) => {
        if (this.modes.includes(e.mode)) {
          switch (true) {
            case e.message.includes("🍒"):
              //girl2
              if (this.girls.length >= 2) {
                this.questionsPlayable.push(e);
              }
              break;
            case e.message.includes("🍑"):
              //girl1
              if (this.girls.length >= 1) {
                this.questionsPlayable.push(e);
              }
              break;

            case e.message.includes("🥒"):
              //guy2
              if (this.guys.length >= 2) {
                this.questionsPlayable.push(e);
              }
              break;
            case e.message.includes("🍆"):
              //guy1
              if (this.guys.length >= 1) {
                this.questionsPlayable.push(e);
              }
              break;

            default:
              this.questionsPlayable.push(e);
              break;
          }
        }
    });

    //?randomize the array
    this.shuffle(this.questionsPlayable);

    //?match the size of the [questionsPlayables] with the number of turn
    if (this.questionsPlayable.length > parseInt(this.turn)) {
      this.questionsPlayable.length = parseInt(this.turn);
    }

    //?replace emojis and check if there is a async questions and place it after the right thing
    this.questionsPlayable.forEach((e, i) => {
      //?replace the emojis in questions
      this.$nextTick(() => {
        this.shuffle(this.guys);
        this.shuffle(this.girls);
        this.shuffle(this.allPlayers);
        let str = e.message;
        let quest = str;

        if (quest.includes("🍒")) {
          quest = this.replaceEmoji(quest, "🍒", this.girls[1].name);
        }
        if (quest.includes("🍑")) {
          quest = this.replaceEmoji(quest, "🍑", this.girls[0].name);
        }
        if (quest.includes("🥒")) {
          quest = this.replaceEmoji(quest, "🥒", this.guys[1].name);
        }
        if (quest.includes("🍆")) {
          quest = this.replaceEmoji(quest, "🍆", this.guys[0].name);
        }
        if (quest.includes("🍉")) {
          quest = this.replaceEmoji(quest, "🍉", this.allPlayers[1].name);
        }
        if (quest.includes("🍍")) {
          quest = this.replaceEmoji(quest, "🍍", this.allPlayers[0].name);
        }

        let strD = e.deuxiemeMessage;
        let questD = strD;
        if (e.deuxiemeMessage != undefined) {
          if (questD.includes("🍒")) {
            questD = this.replaceEmoji(questD, "🍒", this.girls[1].name);
          }
          if (questD.includes("🍑")) {
            questD = this.replaceEmoji(questD, "🍑", this.girls[0].name);
          }
          if (questD.includes("🥒")) {
            questD = this.replaceEmoji(questD, "🥒", this.guys[1].name);
          }
          if (questD.includes("🍆")) {
            questD = this.replaceEmoji(questD, "🍆", this.guys[0].name);
          }
          if (questD.includes("🍉")) {
            questD = this.replaceEmoji(questD, "🍉", this.allPlayers[1].name);
          }
          if (questD.includes("🍍")) {
            questD = this.replaceEmoji(questD, "🍍", this.allPlayers[0].name);
          }
        }

        //?replace emojis in the question
        e.message = quest;
        e.deuxiemeMessage = questD;

        if (e.deuxiemeMessage != undefined) {
          this.questionsPlayable.splice(
            i +this.counterQuestionsAdded + this.getRandomInt(e.minTurn, e.maxTurn),
            0,
            { mode: e.mode, async: e.async, message: e.deuxiemeMessage }
          );
          this.counterQuestionsAdded+=1;
        }
      });
    });


  },
};
</script>

<style></style>
